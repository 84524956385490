/* -------------- */
/* -   GENERAL  - */
/* -------------- */

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  color: #000000;
}

.App {
  display:block;
  clear:both;
  overflow-y: visible;
  overflow-x: hidden;
}

.parentDiv {
  position: relative;
  display: block;
}

.hidden {
  display:none !important;
}

h1 {
  text-align: left;
  font-family: 'Playfair Display';
  font-size: 30px !important;
  font-weight: 700 !important;
  font-style: normal;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 0px;
  transition-property: opacity, transform;
  font-stretch: normal;
  font-optical-sizing: auto;
  line-height: initial;
  transition-duration: 350ms;
}

h2 {
  text-align: left;
  font-family: 'Playfair Display';
  font-style: normal;
  font-size: 26px !important;
  font-weight: 700 !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

h3 {
  text-align: left;
font-family: 'Playfair Display';
font-size: 22px !important;
font-weight: 600 !important;
font-style: normal;
letter-spacing: 0px;
color: #000000;
opacity: 1;
}

h4 {
  text-align: left;
font-family: 'Playfair Display';
font-size: 18px !important;
font-weight: 500 !important;
font-style: normal;
letter-spacing: 0px;
color: #363636;
opacity: 1;
}

p {
  text-align: left;
font: normal normal normal 14px/18px 'Open Sans';
font-size: 15px;
font-weight:normal;
font-style: normal;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-top:4px;
margin-bottom:4px;
line-height: 20px !important;
}

blockquote p {
  font-style: italic;
  text-align: center;
  font-size: 13px !important;
}

.boldText {
  font-weight: 700;
}

a {
  color: teal !important;
  font: normal normal normal 15px/20px 'Open Sans';
}

a:hover {
  color: #000000 !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.content {
  width: 100vw;
  height: inherit;
  min-height: 200px;
}

.centerText {
  margin-top:16px;
  text-align: center;
}

.centerText h1 {
  text-align: center;
}

.centerText h2 {
  text-align: center;
}

.horDiv {
  margin-left:80px;
  margin-right:80px;
  border-bottom: 1px solid #363636;
  margin-bottom: 24px;
  margin-top: 24px;
}

.pageSection {
  /* padding-top: 24px; */
  padding-left: 100px;
  padding-right: 100px;
}

.pagination > li > a, .pagination > li > span {
  border-radius: 80px !important;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border: 1px solid teal;
}

.pagination > .active > a{
  background-color: teal !important;
  color: white !important;
  border: 1px solid teal;
}

.pagination > li > a:hover {
  background-color: #e7edf6 !important;
  color: teal !important;
  border: 1px solid teal;
}

.disabled > a > span{
  color: #D1D2D3 !important;
}

.pagination > li > button, .pagination > li > span {
  border-radius: 80px !important;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border: 1px solid teal;
  color: teal !important;
}

.pagination > .active > button{
  background-color: teal !important;
  color: white !important;
  border: 1px solid teal;
}

.pagination > li > button:hover {
  background-color: #e7edf6 !important;
  color: teal !important;
  border: 1px solid teal;
}

.disabled > button > span{
  color: #D1D2D3 !important;
}

.sortBlogs, .sortCountries, .sortQuizzes {
  /* margin-bottom:10px; */
  height:36px;
}
  
.sortTitle {
  color: #686B6C;
  display: inline;
  float:left;
  margin-bottom:0px !important;
  padding-top:4px;
  padding-right:10px;
}

.dropdown {
  display: inline;
  float:left;
}

.btn-blue-outline {
  border: 1px solid teal !important;
  border-radius: 60px !important;
  width: 100px;
  height: 28px;
  font-family: 'Libre Franklin' !important;
  font-weight:400 !important;
  font-size: 14px !important;
}

.btn-outline-teal {
  --bs-btn-color: teal !important;
--bs-btn-border-color: teal !important;
--bs-btn-hover-color: #fff !important;
--bs-btn-hover-bg: teal !important;
--bs-btn-hover-border-color: teal !important;
--bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
--bs-btn-active-color: #fff !important;
--bs-btn-active-bg: teal !important;
--bs-btn-active-border-color: teal !important;
--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
--bs-btn-disabled-color: teal !important;
--bs-btn-disabled-bg: transparent !important;
--bs-btn-disabled-border-color: teal !important;
--bs-gradient: none !important;
}

.dropButText {
  color: teal !important;
  font-family: 'Libre Franklin';
  font-weight:400;
  font-size: 14px !important;
  padding-top: 2px !important;
  text-align: left !important;
  padding-left: 15px !important;
}

.dropdown-toggle #text{
  float: left;
}

.dropdown-toggle::after {
  float: right;
  padding-top: 8px !important;
  margin-top: 8px !important;
}

.dropdown-menu {
  border: 1px solid teal !important;
  border-radius: 14px !important;
  color: teal !important;
  font-family: 'Libre Franklin';
  font-weight:400;
  font-size: 14px !important;
  width: 100px !important;
  min-width: 100px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-top: -1px !important;
}

.dropdown-menu .active {
  background-color: teal !important;
  color: #ffffff !important;
}

.dropdown-item:hover {
  background-color: #e7edf6 !important;
  color: teal !important;
}

.firstDD {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}

.lastDD {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}

.tags {
  margin-top: 8px;
}

.tags span {
  float:left;
  margin-right:5px;
  background-color: #FFFFFF !important;
  border: 1px solid Teal !important;
  color: teal !important;
  margin-bottom: 2px;
  padding: 8px;
  padding-top: 6px;
  --bs-badge-border-radius: 4px !important;
  font-weight: 500;
}

.tags span:hover {
  color: #fff !important;
  background-color: teal !important;
}

.backLink {
  text-align: left;
  margin-bottom:10px;
}

.backLink a {
  font: normal normal normal 12px/16px 'Open Sans' !important;
}

.bannerImage {
  margin-bottom:12px;
  padding-bottom:16px;
  border-bottom: 1px solid #DDDDDD;
}

.shareButtonsTop {
  float:right;
  margin-top:8px;
}

.shareButtonsBottom {
  float:left;
  width: 100%;
  text-align: left;
  padding-top: 0px;
}

.shareText {
  display: inline-block;
  vertical-align: middle;
}

.shareP {
  font: normal normal normal 14px/16px 'Barlow Condensed';
  font-weight: 500;
  text-align: right;
  margin-bottom: -2px !important;
  /* vertical-align: middle;
  height: 26px; */
}

.shareIcon {
  display: inline-block;
  margin-left: 12px;
}

.shareIcon svg path{
  fill: teal;
}

.shareIcon svg:hover path{
  fill: #000000;
}

#mainContent {
  max-width: 1800px;
}

/* -------------- */
/* -   HEADER   - */
/* -------------- */

.header {
  padding-left:80px !important;
  padding-right:80px !important;
  height: 160px;
  position: relative;
  width: 100vw;
  padding-top: 8px;
}

.headerDisp {
  height: 100px;
  border-bottom: 1px solid #363636;
}

.headerDivs {
  display: inline-block;
}

#headerLogo {
  position:absolute;
  top: 20px;
  left: 0;
  width: 100vw;
  height: 0px;
  overflow: visible !important;
  z-index: 81 !important;
}

.totlogo {
  width: 240px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
}

#navLinks {
  position:absolute;
  top: 20px;
  left: 0;
  width: 100vw;
  height: 120px;
  margin-left: 0px;
  margin-top: 20px;
  z-index: 80 !important;
}

#navLinksRow {
  margin-left: 0px;
  margin-right: 0px;
}

#navLinksLeft {
  float:right;
  text-align:right;
  padding-right: 140px;
  width: 50vw;
}

#navLinksRight {
  float:left;
  text-align:left;
  padding-left: 140px;
  width: 50vw;
}

.headerNav {
  display: inline-block;
  height: 27px;
  font: normal normal normal 22px/28px 'Barlow Condensed';
  letter-spacing: 0px;
  color: #000000  !important;
  opacity: 1;
  text-decoration: none;
  margin-top: 25px;
  margin-left: 30px;
  margin-right: 30px;
}

.headerNav:hover {
  color: teal !important;
}

.hamburgerMenu {
  position: relative;
  visibility: hidden;
  z-index: 99;
}

.hamburger {
  visibility: hidden;
  width: 2rem;
  height: 2rem;
  z-index: 100;
  position: absolute;
  top: 32px;
  right: 40px;
}

.bar-one,
.bar-two,
.bar-three {
  width: 2rem;
  height: 0.25rem;
  background: #000000;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
  margin-top:6px;
}

.bar-one,
.bar-three {
  transform: rotate(0);
}
.bar-two {
  opacity: 1;
  transform: translateX(0);
}

.open .bar-one {
    transform: rotate(45deg);
  }
.open .bar-two {
    opacity: 0;
    transform: translateX(20px);
  }
.open .bar-three {
    transform: rotate(-45deg);
  }

.hamMenu {
  position: absolute;
  width: 220px;
  height: 380px;
  margin: -90px 0 0 0;
  padding-top: 40px;
  right: -100px;
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  overflow: visible !important;
}

.open .hamMenu {
  transform: scale(1.0, 1.0);
  opacity: 1;
}

.hamburgerNav {
  text-decoration: none !important; 
  transition: color 0.3s ease;
  display: block;
  font: normal normal normal 20px/26px "Open Sans";
  letter-spacing: 0px;
  color: #363636;
  margin-top: 8px;
}

.hamburgerNav:hover {
  color: #4990c4;
}

.hamMenu .active {
    /* font: normal normal semi-bold 20px/26px Chivo; */
    font-weight: 600;
  }

.hamMenu ul {
  list-style-type: none;
  padding:0px;
  width:200px;
}

/* -------------- */
/* -   FOOTER   - */
/* -------------- */


.footer {
  height:200px;
  font-family: "Barlow Condensed";
  position: relative;
  border-top: solid 1px #000000;
  margin-top: 40px;
  padding-top: 20px;
}

#footerLogoPanel {
  padding-left:40px;
}

#footerLogo {
  width: 100%;
  margin-left: 0px !important;
  padding-left: 0px !important;
  float:left;
}

#totFooterlogo {
  float:left;
  margin-top:0px;
  margin-bottom:16px;
  margin-left: 0px !important;
  padding-left: 0px !important;
}

.copyright {
  display: block;
}

.copyright p {
  font: normal normal normal 12px/16px 'Open Sans';
  padding-left: 16px;
}

.footerDivs ul {
  list-style-type: none;
  text-align: left;
  color: #1B2224;
  padding-left: 0px;
}

.footerLinks {
    margin-left:0px !important;
}

.footerLinksTitle {
  color: #000000;
}

.footerLinksTitle p{
  font: normal normal normal 18px/24px 'Playfair Display';
  font-weight: 700;
  color: #000000;
}

.footerNav {
  font: normal normal normal 16px/30px 'Barlow Condensed';
  text-decoration: none;
}

.footerLinksTitle {
  margin-top:12px;
}

/* -------------- */
/* -  ADVERTS   - */
/* -------------- */

.adLand {
  border-bottom: solid 1px #DDDDDD !important;
  margin-bottom: 16px;
}

.googleAdLand {
  display: block;
  clear: both;
}

.affilAd {
  margin-top: 16px;
  padding-bottom: 16px;
  display: block;
  clear: both;
}



/* -------------- */
/* -  HOMEPAGE  - */
/* -------------- */

/* #homeContent {
  padding-top:24px;
} */

.homeSection {
  padding-top:6px;
}

.featuredArticles {
  padding-left:100px;
  padding-right:100px;
}

.featuredArticleTiles {
  margin-left:0px !important;
  margin-right:0px !important;
}

.mainArticle {
  width: calc(50% - 36px) !important;
  margin-right: 12px !important;
  margin-left: 24px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.mobileTileLayout {
  width: calc(50% - 36px) !important;
  margin-left: 12px !important;
  margin-right: 24px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.moreArticles {
  width: 50% !important;
  margin-left:0px !important;
  margin-right:0px !important;
  padding-left:0px !important;
  padding-right:0px !important;
  display: inline-block;
}

.imgHolder {
  position: relative;
  text-align: center;
  color: white;
  overflow: hidden;
}

.imgHolderLeft {
  margin-right: 12px;
}

.imgHolderRight {
  margin-left: 12px;
}

.imgHolderBottom {
  margin-top: 24px;
}

.cardContent {
  position: absolute;
  top:0px;
  left:0px;
  height:100%;
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: linear-gradient(
  180deg,
  hsla(0, 0%, 35.29%, 0) 0%,
  hsla(0, 0%, 34.53%, 0.034375) 16.36%,
  hsla(0, 0%, 32.42%, 0.125) 33.34%,
  hsla(0, 0%, 29.18%, 0.253125) 50.1%,
  hsla(0, 0%, 24.96%, 0.4) 65.75%,
  hsla(0, 0%, 19.85%, 0.546875) 79.43%,
  hsla(0, 0%, 13.95%, 0.675) 90.28%,
  hsla(0, 0%, 7.32%, 0.765625) 97.43%,
  hsla(0, 0%, 0%, 0.8) 100%
);
}

.blogTileLink {
  text-decoration: none;
}

.blogTileLink h2, .blogTileLink h3 {
  color: #FFFFFF !important;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 1px 8px;
  padding-left:12px;
  padding-right:12px;
}

.latestArticleColumn {
  width: 100%;
  padding: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-right: 1px solid #DDDDDD;
  height: 100%;
  vertical-align: top;
}

.lastColumn {
  border-right: none !important;
}

.latestArticles {
  position: relative;
  margin-left: 0px !important;
  margin-right: 0px !important;
  display: block !important;
}

.latestArticles .ais-Hits-item {
  width: 25%;
  display: inline-block !important;
  box-shadow: none;
  vertical-align: top !important;
  padding: 0px !important;
}

.latestArticles .ais-Hits {
  max-width: 100% !important;
  display: flex;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 24px;
  margin-bottom: 20px !important;
  z-index: 85 !important;
  min-height: 170px;
}

.latestArticles .ais-Hits,
.latestArticles .ais-Hits-list,
.latestArticles .ais-Hits-item {
  height: inherit;
  text-align: center;
  display: flex !important;
}

.artType {
  text-align: center;
  font: normal normal normal 14px/18px 'Barlow Condensed';
  margin-top: 12px;
  color: #555555;
}

.articleLink {
  text-decoration: none !important;
}

.articleLink h2 {
  color: teal;
}

.articleLink h2:hover {
  color: #000000;
}

.linkButton {
  padding: 4px;
  font: normal normal normal 15px/20px 'Open Sans';
  color: teal !important;
}

.linkButton:hover {
  color: #000000;
}

.published {
  font: normal normal normal 12px/16px 'Open Sans';
  color: #555555;
}

.latestCountries, .latestQuizzes {
  position: relative;
}

.countryList, .quizList {
  max-width: 100% !important;
  display: flex;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 24px;
  margin-bottom: 20px !important;
  z-index: 85 !important;
  height: inherit;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.countryListCountry, .quizListQuiz {
  width: 25% !important;
  display: flex;
  box-shadow: none;
  vertical-align:top;
  padding: 0px !important;
  height: inherit;
}

.countryListColumn, .quizListColumn {
  width: 100%;
  padding: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-right: 1px solid #DDDDDD;
  height: inherit;
}

.countryListLink, .quizListLink {
  text-decoration: none !important;
}

.countryListLink h2 {
  color: teal !important;
}

.countryListLink h2:hover {
  color: #000000 !important;
} 

.quizListLink h2:hover {
  color: #000000!important;
}

.quizListLink h2 {
  color: teal !important;
} 

.countryListColumn .summaryBlock, .quizListColumn .summaryBlock {
  margin-top: 16px;
}

.quizListLink h2 {
  font-weight: 600 !important;
}

/* -------------- */
/* -   SOCIAL   - */
/* -------------- */

.socialLinks {
  padding-left: 120px;
  padding-right: 120px;
  margin-top: 16px;
}

.socialLinks svg {
  fill: teal;
}

.socialLinks svg:hover {
  fill: #000000;
}

.socialName {
  text-align: center;
  font: normal normal normal 16px/24px 'Barlow Condensed';
  margin-top: 6px;
  color: #555555;
}

.socialLinks a {
  text-decoration: none !important;
}

.socialLinks svg {
  height: 48px;
  width: 48px;
}

/* -------------- */
/* -    BLOG    - */
/* -------------- */

.blogListBlog {
  padding-bottom: 24px;
  border-bottom: 1px solid #DDDDDD;
  padding-top: 24px;
}

.blogListLink {
  text-decoration: none !important;
}

.blogListLink h2 {
  color: teal !important;
}

.blogListLink h2:hover {
  color: #000000 !important;
}

.blogListBlog .published {
  margin-bottom: 8px;
}

.blogPagination {
  margin-top:24px;
}

.authorMeta {
  text-align: left;
  font: normal normal normal 14px/18px 'Barlow Condensed';
  margin-top: 0px;
  color: #555555;
  margin-bottom: 8px;
}

.blogMeta {
  margin-bottom: 0px;
  font: normal normal normal 12px/16px 'Open Sans';
}

.introSection {
  border-bottom: solid 1px #DDDDDD !important;
  margin-bottom: 16px;
  padding-bottom: 12px;
}

.blogContent {
  border-bottom: solid 1px #DDDDDD !important;
  overflow:hidden;
}

.blogContent img{
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-bottom: 10px;
}

/* .blogContent li {
  text-align: left !important;
  font: normal normal normal 14px/18px 'Open Sans';
}

.blogContent ol {
  font: normal normal normal 14px/18px 'Open Sans';
} */

.quizContent ul,  .mainBlogContent ul{
  float:left;
  padding-left: 50px;
  padding-bottom: 8px;
  text-align: left;
font: normal normal normal 14px/18px 'Open Sans';
font-size: 15px !important;
font-weight:normal;
font-style: normal;
letter-spacing: 0px;
color: #000000;
opacity: 1;
line-height: 20px !important;
width: 100%;
}

.quizContent ul li, .mainBlogContent ul li{
  list-style-position: outside;
}

.quizContent ul li::marker, .mainBlogContent ul li::marker{
  font-weight:700;
}

p + ul {
  margin-top: -8px;
}

/* -------------- */
/* -   SEARCH   - */
/* -------------- */

.ais-SearchBox {
  margin-bottom:10px !important;
}

.ais-SearchBox-input {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.ais-Hits-item {
  display:block !important;
  box-shadow: none !important;
  padding: 12px !important;
}

.searchPanel .ais-Hits-item {
  border-bottom: solid 1px #DDDDDD !important;
}

.ais-Hits-item .published {
  margin-bottom: 0px !important;
}

.ais-RefinementList {
  width:25% !important;
  /* display:inline-block !important; */
  float: left !important;
  padding-top:20px !important;
}

.ais-RefinementList-count {
  float: right !important;
  color: teal !important;
  background-color: #e7edf6 !important;
  border: 1px solid teal !important;
  text-align: center !important;
  min-width: 16px !important;
  /* adding-left: 3px !important; */
}

.ais-RefinementList-checkbox {
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.ais-Hits {
  max-width:75% !important;
  /* display:inline-block !important; */
  float: right !important;
  margin-bottom: 20px !important;
}

.ais-Pagination {
  max-width:75% !important;
  display:inline-block !important;
  margin-bottom: 20px !important;
  margin-left: 25% !important;
}

.ais-Pagination-link {
  border-radius: 80px !important;
  width: 40px !important;
  height: 40px !important;
  margin: 0 5px !important;
  border: 1px solid teal !important;
  background-image: none !important;
  color: teal !important;
}

.ais-Pagination-item--disabled .ais-Pagination-link {
  color: #D1D2D3 !important;
  border: 1px solid #dee2e6 !important;
}

.ais-Pagination-item--selected .ais-Pagination-link{
  background-color: teal !important;
  color: white !important;
  border: 1px solid teal;
  box-shadow: none !important;
}

.ais-Pagination-item .ais-Pagination-link:hover {
  background-color: #e7edf6 !important;
  color: teal !important;
  border: 1px solid teal;
}

.ais-Pagination-item--disabled .ais-Pagination-link:hover {
  background-color: #ffffff !important;
  color: #D1D2D3 !important;
  border: 1px solid #dee2e6 !important;
  cursor: default !important;
}

.ais-RefinementList-showMore {
  border: 1px solid teal !important;
  background-image: none !important;
  color: teal !important;
  /* display: inline-block !important; */
  float: left !important;
  /* margin-left: 24px !important; */
}

.ais-RefinementList-showMore:hover {
  background-color: #e7edf6 !important;
  color: teal !important;
  border: 1px solid teal;
}

.ais-RefinementList-showMore:active {
  border: 1px solid teal !important;
}

.ais-RefinementList-showMore:focus {
  border: 1px solid teal !important;
  box-shadow: none !important;
}

.searchLink {
  text-decoration: none !important;
}

.searchLink h2 {
  color: teal;
}

.searchLink h2:hover {
  color: #000000;
}

/* -------------- */
/* - COUNTRIES  - */
/* -------------- */

.countryContent {
  border-bottom: solid 1px #DDDDDD !important;
}

.countryListPanel {
  padding-bottom: 24px;
  border-bottom: 1px solid #DDDDDD;
  padding-top: 24px;
}

.countryPagination {
  margin-top:24px;
}

.countryPostContent .shareButtonsTop {
  margin-top: 0px !important;
}

.relatedBlogs {
  border-bottom: 1px solid #DDDDDD;
  padding-bottom: 24px;
  margin-bottom: 16px;
}

.relatedLink {
  text-decoration: none !important;
}

.relatedLink h2 {
  text-decoration: none !important;
  color: teal;
}

.relatedLink h2:hover {
  color: #000000;
}

/* -------------- */
/* -    ABOUT   - */
/* -------------- */

.authorBlock {
  padding-top: 24px;
}

/* -------------- */
/* -   CONTACT  - */
/* -------------- */

span.asterix {
  color: salmon;
}

.contactForm {
  padding-left:0px !important;
}

.form-label {
  font-weight:500;
  font-family: "Open Sans";
  float: left;
  margin-top:5px;
}

.recaptchaArea {
  margin-top: 10px;
  margin-bottom: 10px;
}

.btnContact {
  float: left;
  margin-bottom: 30px;
  display: block;
}

.btnContact button {
  --bs-btn-bg: #ffffff;
  --bs-btn-color: teal !important;
--bs-btn-border-color: teal !important;
--bs-btn-hover-color: #fff !important;
--bs-btn-hover-bg: teal !important;
--bs-btn-hover-border-color: teal !important;
--bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
--bs-btn-active-color: #fff !important;
--bs-btn-active-bg: teal !important;
--bs-btn-active-border-color: teal !important;
--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
--bs-btn-disabled-color: teal !important;
--bs-btn-disabled-bg: transparent !important;
--bs-btn-disabled-border-color: teal !important;
--bs-gradient: none !important;
}

.confirmMessage {
  padding-left:80px;
  padding-top:5px;
  display: block;
}

/* -------------- */
/* -     FUN    - */
/* -------------- */

.featuredGame {
  border-bottom: solid 1px #DDDDDD !important;
  padding-top: 24px;
  padding-bottom: 24px;
}

.gamesList h2 {
  margin-bottom: 0px;
}

.gameListLink {
  text-decoration: none !important;
}

.gameListLink h2, .gameListLink h3 {
  text-decoration: none !important;
  color: teal;
}

.gameListLink h2:hover, .gameListLink h3:hover {
  color: #000000;
}

.funQuizList {
  padding-bottom: 24px;
  border-bottom: 1px solid #DDDDDD;
  padding-top: 24px;
}

.funQuizList .published {
  margin-bottom: 8px;
}

.quizPagination {
  margin-top:24px;
}

#quizAnswers {
  border-bottom: 1px solid #DDDDDD;
  overflow:hidden;
}

/* -------------- */
/* -    GAMES   - */
/* -------------- */

.longPanel, .latPanel, .statePanel {
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 10px;
}

.longImages, .latImages, .stateImages {
  position: relative;
}

.stateImages {
  max-width: 700px;
  margin: 0 auto;
}

.longMap, .latMap, .stateMap {
  position: relative;
  top:0px;
  left:0px;
}

.longMapOverlay, .latMapOverlay, .stateMapOverlay {
  position: absolute;
  top:0px;
  left:0px;
}

.longControls, .latControls, .stateControls {
  margin-top:10px;
}

.longControls h3, .latControls h3, .stateControls h3 {
  text-align:center;
}

.sliderControl {
  margin-top: 10px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.sliderLabel {
  font-size:16px !important;
  font-weight: 700;
  padding-top: 8px;
}

#noLabel {
  text-align: right;
}

.rangeButton {
  margin-top:10px;
}

.resultRow {
  margin-bottom:10px;
  text-align:center;
}

.resultRow h2 {
  color: #0E7A9E;
  text-align:center;
}

.longPlaces, .latPlaces, .statePlaces {
  margin-top:10px;
  border: 1px solid gainsboro;
  border-radius: 10px;
  padding: 10px;
}

.longPlaces h4, .latPlaces h4, .statePlaces h4 {
  text-align: center;
}

.placesList p {
  text-align: left;
  margin-bottom: 0;
  font-size:14px !important;
}

.placesList {
  margin-bottom: 20px;
  margin-top:10px;
  padding-left: 0px !important;
  padding-right: 00px !important;
}

.st0 { 
  fill:#9FCAD8;
  stroke:#FFFFFF;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:11;
}

.st1 {
  fill:#FFFFFF;
  stroke:#A9A9FF;
  stroke-linecap:round;
  stroke-linejoin:round;stroke-miterlimit:11;
}

.st2 {
  fill:none;
  stroke:#A9A9FF;
  stroke-linecap:round;
  stroke-linejoin:round;
  stroke-miterlimit:11;
}

#Washington:hover {
  fill:#763255;
}

/* -------------- */
/* -   QUIZZES  - */
/* -------------- */

.quizContent ol,  .mainBlogContent ol{
  float:left;
  padding-left: 0px;
  text-align: left;
font: normal normal normal 14px/18px 'Open Sans';
font-size: 15px !important;
font-weight:normal;
font-style: normal;
letter-spacing: 0px;
color: #000000;
opacity: 1;
line-height: 20px !important;
width: 100%;
}

.quizContent ol li, .mainBlogContent ol li{
  list-style-position: inside;
}

.quizContent ol li::marker, .mainBlogContent ol li::marker{
  font-weight:700;
}

.quizContent img {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  margin-bottom: 10px;
}

#quizQuestions {
margin-bottom: 24px;
padding-bottom: 24px;
border-bottom: 1px solid #DDDDDD;
}

.quizButtons {
margin-top:36px;
margin-bottom: 24px;
height: 24px;
position: relative;
border-bottom: 1px solid #DDDDDD;
padding-bottom: 60px;
}

.quizButton {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

/* .quizButtons .hidden {
  width: 0px;
  padding:0px;
}

.quizContent .hidden {
  height: 0px;
  padding: 0px;
} */


/* -------------- */
/* -    MEDIA   - */
/* -------------- */

@media only screen and (max-width: 1200px) {
  .featuredArticles, .countryList, .latestArticles .ais-Hits, .quizList{
      padding-left:50px;
      padding-right:50px;
   }

   #navLinksLeft {
    padding-right: 120px;
   }

   #navLinksRight {
    padding-left: 120px;
   }

   .pageSection { 
    padding-left:50px;
    padding-right:50px;
   }

   #contactSection1 .horDiv {
    margin-left: 30px;
    margin-right: 30px;
  }

}

@media only screen and (max-width: 1024px) {
  .headerNav {
    margin-left: 20px;
    margin-right: 20px;
    font: normal normal normal 22px/28px 'Barlow Condensed';
  }

}

@media only screen and (max-width: 992px) {
  .featuredArticles, .countryList, .latestArticles .ais-Hits, .quizList {
      padding-left:24px;
      padding-right:24px;
   }

   .pageSection { 
    padding-left:24px;
    padding-right:24px;
   }

   .headerNav {
    font: normal normal normal 20px/26px 'Barlow Condensed';
  }
}

@media only screen and (max-width: 920px) {

   #navLinksLeft {
    padding-right: 100px;
   }

   #navLinksRight {
    padding-left: 100px;
   }

   .socialLinks {
    padding-left: 10px;
    padding-right: 10px;
  }

}

@media only screen and (max-width: 820px) {

  #navLinksLeft {
   padding-right: 100px;
  }

  #navLinksRight {
   padding-left: 100px;
  }

  .headerNav {
    margin-left: 16px;
    margin-right: 16px;
    font: normal normal normal 20px/26px 'Barlow Condensed';
  }

}

@media only screen and (max-width: 768px) {
  .hamburgerMenu {
    visibility: visible;
  }

  .hamburger{
    visibility: visible;
  }

  #navLinks {
    visibility: hidden;
  }

  .featuredArticles {
    padding-left:50px;
    padding-right:50px;
  }

  .mainArticle {
    width: calc(100% - 48px) !important;
    margin-right: 24px !important;
    margin-left: 24px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  
  .mobileTileLayout {
    width: calc(100% - 48px) !important;
    margin-left:24px !important;
    margin-right: 24px !important;
    padding-left:0px !important;
    padding-right:0px !important;
  }

  .imgHolderTop {
    margin-top: 24px;
  }

  .countryList, .quizList {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 0px !important;
  }

  .countryListCountry, .quizListQuiz {
    width: 50% !important;
    margin-bottom: 24px;
  }

  .middleColumn {
    border-right: none;
  }

  #homeCountries .centerText, #homeQuizzes .centerText {
    margin-top: 0px;
  }

  .latestArticles .ais-Hits {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 0px !important;
  }

  .latestArticles .ais-Hits-item {
    width: 50% !important;
    margin-bottom: 24px;
  }

  .latestArticles .ais-Hits .ais-Hits-item {
    height: auto;
    }

  #footerNavPanel {
    padding-left:0px;
    padding-right:0px;
  }

  #footerNavLinks li {
    margin-right: 35px;
  }

  #footerLinksCol2 {
    padding-left:15px;
  }

  .authorBlock, .gameBlock {
    padding-top:0px;
    padding-left:15px;
  }

  .authorImage {
    max-width: 200px;
    max-height:200px;
    float:left;
  }

  .authorCol{
    padding-left:0px !important;
  }

  .blogListImage {
    max-width: 50% !important;
    max-height: 280px;
    float:left;
    margin-bottom: 10px;
  }

  .pageSection { 
    padding-left:50px;
    padding-right:50px;
   }

  #contactSection1 .horDiv {
    margin-left: 30px;
    margin-right: 30px;
  }

  .socialLinks {
    padding-left: 10px;
    padding-right: 10px;
  }

  .searchPanel {
    display: flex;
   flex-wrap:wrap;
  }
  
  .ais-RefinementList {
    width: 100% !important;
    order: 4 !important;
  }

  .ais-Pagination {
    max-width: 100% !important;
    order: 3 !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .ais-Hits {
    max-width: 100% !important;
    order: 2 !important;
  }

  .ais-SearchBox {
    width: 100% !important;
    order: 1 !important;
  }

  /* .searchImg {
    max-width: 30% !important;
    padding-bottom: 10px;
  } */

  .relatedCol {
    padding-left: 0px !important;
  }
 
}

@media only screen and (max-width: 763px) {

  #navLinks { 
    width:0px;
    height:0px;
  }
}


@media only screen and (max-width: 576px) {
  .footer {
    height: auto;
  }

  #footerLinksCol2 {
    padding-left:0px;
    margin-left:-20px;
  }

  #footerNavPanel {
    padding-left: 30px;
  }

  /* #footerLogo {
    margin-left:20px;
  }

  #footerNavPanel {
    margin-left:50px;
  } */

  .copyright {
    margin-left:0px;
  }

  .container {
    padding-left:0px !important;
    padding-right:0px !important;
  }

}